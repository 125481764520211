@import "@xala/common-ui/src/scss/Variables.scss";
@import "~react-quill/dist/quill.snow.css";

.gm-text-editor {
  line-height: normal;
  background: #2c2c2e;
  border-radius: $border-radius-base;

  .ql-toolbar,
  .ql-container {
    border: 0;
  }

  .ql-editor {
    min-height: 190px;
  }

  .ql-toolbar {
    border-bottom: $border-width-base $border-style-base rgba($white, 0.15);
  }

  .ql-picker {
    .ql-picker-label {
      border: 0;
    }
  }

  .ql-picker-options {
    color: #2c2c2e;
  }

  .ql-stroke {
    stroke: $white;
  }

  .ql-fill {
    fill: $white;
  }

  .ql-picker {
    color: $white;
  }

  .ql-formats:last-of-type {
    float: right;
  }
}
