.AuthContainer {
  width: 100%;
  height: 100%;

  &__Content {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;

    & .section-grid-item {
      height: 400px;
    }
  }

  &__FooterLink {
    text-decoration: underline;
    color: #fff;
    opacity: 0.6;
    transition: opacity 0.3s ease-in-out;

    &:hover {
      opacity: 1;
      color: #fff;
      text-decoration: underline;
    }
  }

  &__Error {
    color: rgb(240, 65, 52);
    font-size: 14px;
    height: 40px;
    text-align: center;
  }

  &__Logo {
    padding: 16px 0 32px 0;
    text-align: center;
  }
}
