.SectionMenuItemAddForm {
  &__Content {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__Table-header {
    display: table;
    padding-bottom: 8px;
    width: 100%;

    .title {
      display: table-cell;
      width: 50%;
    }

    .actions {
      display: table-cell;
      text-align: right;
      width: 50%;
      padding-right: 42px;
    }

    button {
      margin-left: 8px;
    }
  }
}


.ApplicationSectionMenuItems__Properties {
  position: absolute;
  top: -16px;
  right: -460px;

  .section-grid-item-opaque-margin {
    background-color: #3E3E3E;
    background-image: none;

  }

  .section-grid-item-content {
    background-color: #3E3E3E;

  }
}



