.ListItemHighlightComponentPreview {
  &--image {
    height: 350px;

    img {
      object-fit: cover;
    }
  }

  &--info {
    position: absolute;
    bottom: 70px;
    left: 40px;

    .tag {
    }

    .title {
    }
  }
}
