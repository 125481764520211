.BannerItemAddForm {
  &__Content {
    display: flex;
    flex-direction: column;
    height: 100%;

    .ComponentTitle {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: 155px;
    }
  }

  &__Table-header {
    display: table;
    padding-bottom: 8px;
    width: 100%;

    .title {
      display: table-cell;
      width: 50%;
    }

    .actions {
      display: table-cell;
      text-align: right;
      width: 50%;
      padding-right: 42px;
    }

    button {
      margin-left: 8px;
    }
  }
}

.ApplicationBannerItems__Properties {
  position: absolute;
  top: -16px;
  right: -460px;

  .section-grid-item-opaque-margin {
    background-color: #3e3e3e;
    background-image: none;
  }

  .section-grid-item-content {
    background-color: #3e3e3e;
  }
}
