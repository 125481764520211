@import-normalize;
@import "./Colors.scss";
@import "./Mixins.scss";
@import "./Variables.scss";

html,
body,
#root,
.main {
  height: 100%;
  min-height: 100%;
  overflow-x: hidden;
}

body {
  margin: 0;
}

.center-vertically {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.center-horizontally {
  margin: 0 auto;
}

.center-v-h {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.hidden {
  visibility: hidden;
}
