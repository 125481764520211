.PageContent {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 24px;

  > :first-child {
    flex-grow: 1;
    flex-shrink: 1;
    overflow-y: scroll;
    overflow-x: auto;
    padding-right: 16px;
  }

  .ant-page-header {
    padding-left: 0;
    padding-right: 0;
  }
}

.ant-table-cell a {
  text-decoration: underline;
  text-underline-offset: 4px;
}
