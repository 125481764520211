.Heading {
  display: flex;
  padding-bottom: 16px;
  width: 100%;
  justify-content: space-between;

  button {
    margin-left: 8px;
  }

  .user-modal-actions {
    padding-right: 30px;
  }
}
