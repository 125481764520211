.ListItemCoverComponentPreview {
  border-radius: 10px;
  transition: background 0.2s, transform 0.2s;

  &.ListItemCoverComponentPreview-active {
    transform: scale(1.1);
    background: #1c1c1c;
  }

  &-image-container {
    position: relative;
  }

  &-image {
    height: 100%;
    background-repeat: no-repeat;
    background-size: 100%;

    img {
      object-fit: cover;
    }
  }

  &-status {
    position: absolute;
    padding: 0 20px;
    bottom: 20px;
    width: 100%;

    &-online-indicator {
      font-size: 1rem;
    }

    &-duration {
      font-size: 1rem;
    }
  }

  &-info {
    padding: 8px;

    &-title {
      font-size: 12px !important;
      font-weight: 700 !important;
      letter-spacing: 0.03px !important;
      line-height: 18px !important;
    }

    &-description {
      width: 100%;
      font-size: 10px;
    }
  }
}
