@import "../../scss/Colors.scss";

.tile-list {
  position: relative;

  &-header {
    font-size: 16px;
    line-height: 16px;
  }
  &-header-value {
    font-size: 24px;
    padding-bottom: 10px;
    color: $white;
  }
  &-subheader {
    font-size: 10px;
    opacity: 0.7;
  }
  &-refresh {
    border: 0;
    background: transparent;
    position: absolute;
    top: 8px;
    right: -2px;
    font-size: 16px;
  }
}
