.DictionaryPeople {
  .ant-table {
    min-height: calc(100vh - 220px);
  }

  .FullName {
    cursor: pointer;
    line-height: 1.2px;
    padding-bottom: 2px;
  }
}
