.SalesRevenue {
  &__cards-container {
    display: flex;
    flex-direction: row;
    margin-top: 1vh;
    margin-bottom: 1vh;
    max-width: 80vw;
    margin-left: auto;
    margin-right: auto;
  }
}
