.ApplicationConfigurationDetails {
  height: 100%;

  .rebuild-cache-btn {
    background: #ff8c00;
    border-color: #ff8c00;
  }

  .synchronize-epg-btn {
    background: #4b0082;
    border-color: #4b0082;
  }
}
