.ApplicationMenuPreview {
  padding: 0;
  background-color: #252529;
  position: relative;
  min-height: 70px;

  &__AddButton-container {
    overflow: visible;
    position: relative;
    display: table-cell;
    text-align: center;
    width: 20%;
  }

  &__AddButton {
    background-color: red;
    width: 40px;
    height: 40px;
    position: absolute;
    top: -20px;
    left: 50%;
    transform: translateX(-50%);
  }

  &__Items {
    display: table;
    max-height: 70px;
    width: 100%;

    .Component {
      display: table-cell;
      width: 20%;
      text-align: center;
      height: 60px;
      vertical-align: middle;
      padding: 8px;

      &--content {
        position: relative;
      }

      &--options {
        display: inline-block;
        position: absolute;
        font-size: 16px;
        padding: 4px 4px;
        z-index: 1002;
        top: 0;
        right: 0;
      }
    }

    &--Dropdown-Icon {
      display: block;
      margin: 0 auto;
      font-size: 28px;
      padding-top: 2px;
      -webkit-transform: rotate(90deg);
      -moz-transform: rotate(90deg);
      -o-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
      transform: rotate(90deg);
      cursor: pointer;
    }

    &--Dropdown-Title {
      margin: 0 4px;
      font-size: 10px;
      font-weight: 500;
      line-height: 14px;
      display: block;
      cursor: pointer;
    }

    &--Dropdown-Menu {
      background-color: #252529;
    }
  }
}
